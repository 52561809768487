



















import {
  defineComponent,
  useFetch,
} from '@nuxtjs/composition-api'
import { useBlogTopic } from '@wemade-vsf/blog';
import { SfHeading, SfButton } from '@storefront-ui/vue'

export default defineComponent({
  name: 'TopicList',
  props: {
    active: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    SfHeading,
    SfButton
  },
  setup () {
    const { topics, loadList } = useBlogTopic()

    useFetch (async() => {
      if (!topics.value) {
        await loadList()
      }
    })

    return {
      topics
    }
  }
})
