











import { defineComponent } from '@nuxtjs/composition-api';
import SkeletonLoader from 'components/theme/SkeletonLoader.vue';

export default defineComponent({
  name: 'BlogPostSkeleton',
  props: {
    imageHeight: {
      type: Number,
      default: 200
    }
  },
  components: {
    SkeletonLoader
  }
})
