





















































import { 
  defineComponent,
  useContext,
  useFetch,
  useRoute,
  ref
} from '@nuxtjs/composition-api'
import { useCache } from '@wemade-vsf/cache';
import { useBlog, useBlogTopic } from '@wemade-vsf/blog';
import { getMetaInfo } from '@wemade-vsf/core';
import { useUiHelpers } from '@wemade-vsf/composables';
import type { BlogPost as BlogPostInterface } from '@wemade-vsf/magento-api'
import { SfBreadcrumbs, SfButton, SfLoader } from '@storefront-ui/vue';
import TopicList from 'components/blog/TopicList.vue';
import BlogPost from 'components/blog/BlogPost.vue';
import BlogPagination from 'components/blog/BlogPagination.vue';
import BlogPostSkeleton from 'components/blog/BlogPostSkeleton.vue';

interface PageInfo {
  currentPage: number,
  endPage: number,
  hasNextPage: boolean,
  hasPreviousPage: boolean
};

export default defineComponent({
  name: 'Blog',
  components: {
    SfBreadcrumbs,
    SfButton,
    SfLoader,
    BlogPost,
    TopicList,
    BlogPagination,
    BlogPostSkeleton
  },
  setup () {
    const { app, localeRoute, i18n } = useContext()
    const { getPostList, getMetaData } = useBlog()
    const loading = ref(true)
    const loadingNext = ref(false)
    const { addTags } = useCache();
    const { loadList, topics } = useBlogTopic()
    const uiHelpers = useUiHelpers()
    const route = useRoute()
    const posts = ref<BlogPostInterface[] | null>(null)
    const pageInfo = ref<PageInfo | null>(null)
    const metaData = ref(null)
    const activeTag = ref<string|null>(null)
    const imageSizes = app.$wm.blog?.imageSizes || { card: 400 }
    const currentPage = ref(1);
    const breadcrumbs = [
      { text: i18n.t('Home'), link: localeRoute({ name: 'home' }).fullPath },
      { text: i18n.t('Blog'), link: localeRoute({ name: 'blog' }).fullPath }
    ]

    const pageSize = app.$wm.blog?.pageSize ?? 15

    if (route.value.query['tag']) {
      activeTag.value = Array.isArray(route.value.query['tag']) ? route.value.query.tag[0] : route.value.query.tag
    }
    if (route.value.query['page']) {
      //@ts-ignore
      currentPage.value = parseInt(route.value.query.page);
    }

    const { fetch } = useFetch(async() => {

      if (!metaData.value) {
        metaData.value = await getMetaData()
      }

      let params = {
        pageSize,
        currentPage: currentPage.value
      }
      if (activeTag) {
        if (!topics.value) {
          await loadList()
        }
        const topic = topics.value.find(t => t.url_key === activeTag.value)
        if (topic) {
          params['topicId'] = topic.topic_id
          params['action'] = 'get_post_by_topic'
        }
      }

      const postListResult = await getPostList(params)

      if (loadingNext.value) {
        if (postListResult) {
          posts.value = [...posts.value ? posts.value : [], ...postListResult?.items || []]
          pageInfo.value = postListResult?.pageInfo || null
        }
        loadingNext.value = false
      } else {
        posts.value = postListResult?.items || null
        pageInfo.value = postListResult?.pageInfo || null
        loading.value = false
      }
     
      addTags([
        {
          prefix: 'BL',
          value: 'G'
        },
        ...posts.value ? posts.value.map(p => {
          return {
            prefix: 'BL',
            value: p.url_key
          }
        }) : []
      ])
    })

    const goToPage = (page: number) => {
      uiHelpers.changePage(page, false)
      currentPage.value = page
      fetch()
      if (window) {
        window.scrollTo(0, 0);
      }
    }

    const nextPage = () => {
      if (!pageInfo.value?.hasNextPage) return;
      loadingNext.value = true;
      uiHelpers.changePage(pageInfo.value.currentPage + 1, false);
      currentPage.value = pageInfo.value.currentPage + 1;
      fetch()
    }

    const changeTagFilter = (url_key: string) => {
      activeTag.value = activeTag.value && activeTag.value === url_key
        ? ''
        : url_key
      
      if (!process.server) {
        const queryParams = activeTag.value ? `?tag=${activeTag.value}` : ''
        window.history.pushState({}, '', `${route.value.path}${queryParams}`)
      }
      fetch()
    }

    return {
      breadcrumbs,
      activeTag,
      changeTagFilter,
      loading,
      loadingNext,
      metaData,
      goToPage,
      nextPage,
      pageInfo,
      posts,
      imageSizes
    }
  },
  head () {
    return getMetaInfo(this.metaData)
  }
})
