
import { defineComponent } from '@nuxtjs/composition-api';
import { SfPagination } from '@storefront-ui/vue';

const ExtendedSfPagination = {
  ...SfPagination,
};

export default defineComponent({
  name: 'BlogPagination',
  extends: ExtendedSfPagination,
  computed: {
    /**
     * On the blog pages we do not want to reload whole page when pagination changes
     */
    hasRouter() {
      return false;
    },
  },
});
